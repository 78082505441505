import { Device } from "@/interface/DeviceInterface";
import { DeviceProjectDetails, InventoryState } from "@/stores/modules/inventoryDevices";
import { AppState } from "@/stores";
import IbotechService from "@/services/ibotechService";
import { MutationTree, ActionTree, GetterTree, ActionContext } from "vuex";

export class IbotechModule {
  vehicleList: Array<object> = [];
  locationList: Array<object> = [];
  setVehicleRecordsList: Array<object> = [];
  setMissingRecordsList: Array<object> = [];

  error: any;
}

const getters: GetterTree<IbotechModule, AppState> = {
  getAllVehiclesData(state: IbotechModule) {
    return state.vehicleList;
  },
  getAllLocationsData(state: IbotechModule) {
    return state.locationList;
  },
  getAllRecordsData(state: IbotechModule) {
    return state.setVehicleRecordsList;
  },
  getMissingRecordsData(state: IbotechModule) {
    return state.setMissingRecordsList;
  }
}


const actions: ActionTree<IbotechModule, AppState> = {
  async getAllVehiclesFun({ commit }: ActionContext<IbotechModule, AppState>) {
    try {
      const res = await IbotechService.getAllVehicles();
      if (res.status === 200) {
        const list = res.data;
        commit("setVehicleList", list);
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },

  async getVehicleRecordsByDate({ commit }: ActionContext<IbotechModule, AppState>, data) {
    try {
      const res = await IbotechService.getVehicleRecordsByDate(data);
      if (res.status === 200) {
        const list = res.data;
        commit("setVehicleRecordsList", list);
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  },

  async getAllLocations({ commit }: ActionContext<IbotechModule, AppState>) {
    try {
      const res = await IbotechService.getAllLocations();
      if(res.status === 200) {
        const list = res.data;
        commit("setLocationList", list);
      }
    } catch (error) {
      return error;
    }
  },

  async getMissingRecords({ commit }: ActionContext<IbotechModule, AppState>) {
    try {
      const res = await IbotechService.getMissingRecords();
      if(res.status === 200) {
        const list = res.data;
        commit("setMissingRecordsList", list);
      }
    } catch (error) {
      return error;
    }
  }



}

const mutations: MutationTree<IbotechModule> = {
  setVehicleList(state: IbotechModule, listOfVehicles: Array<any>) {
    state.vehicleList = listOfVehicles;
  },
  setLocationList(state: IbotechModule, listOfLocations: Array<any>) {
    state.locationList = listOfLocations;
  },
  setVehicleRecordsList(state: IbotechModule, listOfRecords: Array<any>) {
    state.setVehicleRecordsList = listOfRecords;
  },
  setMissingRecordsList(state: IbotechModule, listOfRecords: Array<any>) {
    state.setMissingRecordsList = listOfRecords;
  },

}

export default {
  state: new IbotechModule(),
  getters,
  mutations,
  actions
};
